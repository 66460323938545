import {
    CREATE_TAGS,
    UPDATE_TAGS,
    GET_TAGS,
    DELETE_TAG
} from '../../constants/actionTypes';

const initialState = {
    tags: [],
};


export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TAGS:
            return {
                ...state,
                tags: action.payload
            };
        case CREATE_TAGS:
            return state.tags= [...state.tags, action.payload];
        case UPDATE_TAGS:
            return state.tags= state.tags.map((tag) => (tag._id === action.payload._id ? action.payload : tag));
        case DELETE_TAG:
            return {
                ...state,
                tags: state.tags.filter((tag) => tag._id !== action.payload)
            }
        default:
            return state;
    }
};