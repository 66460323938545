import {
    FETCH_ALL_ARTICLES,
    CREATE_ARTICLES,
    UPDATE_ARTICLES,
    DELETE_ARTICLES,
    GET_ONE_ARTICLE
} from "../constants/actionTypes";
import {
    getAll,
    store,
    updateArticlesApi,
    deleteArticlesApi,
    getOneArticleApi
} from "../api/articles";

export const getArticles = (size,page) => async (dispatch) => {
    try {
        const {data} = await getAll(size,page);
        dispatch({type: FETCH_ALL_ARTICLES, payload: data});
    } catch (error) {
        console.log(error);
    }
};

export const getOneArticle = (size) => async (dispatch) => {
    try {
        const {data} = await getOneArticleApi(size);
        dispatch({type: GET_ONE_ARTICLE, payload: data});
    } catch (error) {
        console.log(error);
    }
};

export const createArticles = (post) => async (dispatch) => {
    try {
        const {data} = await store(post);

        dispatch({type: CREATE_ARTICLES, payload: data});
    } catch (error) {
        console.log(error);
    }
};

export const updateArticles = (id, post) => async (dispatch) => {
    try {
        const {data} = await updateArticlesApi(id, post);

        dispatch({type: UPDATE_ARTICLES, payload: data});
    } catch (error) {
        console.log(error);
    }
};
export const deleteArticles = (id) => async (dispatch) => {
    try {
        await deleteArticlesApi(id);

        dispatch({type: DELETE_ARTICLES, payload: id});
    } catch (error) {
        console.log(error);
    }
};