import React, {useEffect, useState} from 'react';
import "./editVideo.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {Editor} from "react-draft-wysiwyg";
import {ContentState, EditorState} from "draft-js";
import {convertToRaw} from 'draft-js';
import draftToHtml from "draftjs-to-html";
import Layout from "../../layout";
import ProgressBar from "../progress/progress"

import axios from "axios";
import {connect} from "react-redux";
import {deleteArticles, getArticles, getOneArticle} from "../../actions/articles";
import {popUpBgHandle, popUpHandle} from "../../actions/auth";
import htmlToDraft from "html-to-draftjs";
import {MainApi} from "../../api";
import {getVideos} from "../../actions/videos";
import {message} from "antd";


function EditVideo({tags, ages, editId, videos, getVideos, pId, popUpBgHandle}) {
    const [image, setImage] = useState(undefined);
    const [video, setVideo] = useState("");
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [uploadPercentage1, setUploadPercentage1] = useState(0);
    const [bg, setBg] = useState(false)

    const [videosData, setVideosData] = useState({
        free: false,
        title: "",
        subtitle: "",
        description: "",
        categoryId: "",
        childCategoryId: "",
        tags: [],
        imageFile: "",
        active: false,
        file: "",
        videoId: "",
        objective: ""

    })
    console.log(videosData)

    const [age, setAge] = useState(undefined)

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    const [file1, setFile1] = useState("")
    const handleImg = (e) => {

        onImageChange(e);
        if (e.target.files !== undefined) {
            setFile1(e.target.files[0]);
        }
    }

    const [categories, setCategories] = useState(undefined)
    const [tagIds, setTagIds] = useState(undefined)

    useEffect(async () => {
        await axios.get(`${MainApi}/categories`, {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}})
            .then(response => setCategories(response.data))
    }, []);

    useEffect(() => {
        if (ages !== undefined && tags !== undefined) {
            setTagIds(tags);
            setAge(ages)
        }
    }, [tags, ages])

    const handleVideo = e => {
        if (e.target.files !== undefined) {
            setVideo(e.target.files[0]);
        }
    }
    const [uploadVideo, setUploadVideo] = useState({
        title: "", categoryId: "",
    });
    const [res, setRes] = useState(undefined)
    useEffect(async () => {
        const formData = new FormData();
        formData.append('title', uploadVideo.title)
        formData.append('categoryId', parseInt(uploadVideo.categoryId))
        formData.append('file', video)
        if (uploadVideo.title !== "" && uploadVideo.categoryId !== "" && video !== "") {
            setBg(true)
            await axios.post(`${MainApi}/media/video/upload`, formData, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'multipart/form-data boundary=${form._boundary}'
                }, onUploadProgress: progressEvent => {
                    setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
                },
            })
                .then(response => setRes(response))
        }
    }, [video]);

    useEffect(() => {
        if (uploadPercentage === 100) {
            setBg(false)
        }
    }, [uploadPercentage])

    let des = "";
    const blocks = htmlToDraft(des);
    const {contentBlocks, entityMap} = blocks;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState));

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        setVideosData({
            ...videosData, description: draftToHtml(convertToRaw(editorState.getCurrentContent())).toString()
        })
    }

    const [ids, setIds] = useState([])
    const handLeClick = (id) => {
        if (ids.some(b => b === id)) {
            setIds(ids.filter(i => i !== id))
        } else {
            setIds([...ids, id])
        }
    }


    const onSubmit = async e => {
        e.preventDefault();
        popUpBgHandle();
        const formData1 = new FormData();
        if (videosData.childCategoryId !== "" && videosData.description !== "" && videosData.subtitle !== ""
            // ids.length !== 0
        ) {
            formData1.append("id", videos.id);
            formData1.append("title", uploadVideo.title);
            formData1.append("categoryId", uploadVideo.categoryId);
            formData1.append("childCategoryId", videosData.childCategoryId);
            if (file1) {
                formData1.append("file", file1);

            }
            formData1.append("videoId", res.data.id)
            formData1.append('free', videosData.free)
            formData1.append('description', videosData.description)
            formData1.append('active', videosData.active)
            formData1.append('tagIds', ids !== undefined ? ids : "");
            formData1.append('subtitle', videosData.subtitle)
            formData1.append('objective', videosData.objective)
            try {
                await axios.put(`${MainApi}/courses/${videos.id}/update-course-item`, formData1, {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("token")}`,
                        'Content-Type': 'multipart/form-data boundary=${form._boundary}'
                    }, onUploadProgress: progressEvent => {
                        setUploadPercentage1(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));

                    },
                })
                setUploadVideo({
                    title: "", categoryId: "",

                })

                setVideosData({
                    free: false,
                    subtitle: "",
                    description: "",
                    childCategoryId: "",
                    tags: [],
                    imageFile: "",
                    active: false,
                    file: "",
                    videoId: "",
                });
                setIds([]);
                setEditorState("");
                setFile1("")
                setUploadPercentage(0);
                setUploadPercentage1(0);
                setImage(undefined);
                setVideo("");

                message.success("Video edited")
                getVideos(10, pId);
                popUpBgHandle();
            } catch (err) {
                console.log(err)
            }
        } else {
            message.warn("Please fill all fields")
        }

    }

    const style = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.4)",
        display: `${bg ? "block" : "none"}`,
        zIndex: 5000,

    }
    useEffect(() => {
        if (editId !== undefined) {
            setVideosData({
                ...videosData,
                subtitle: videos.subtitle,
                childCategoryId: videos.childCategoryId,
                active: videos.active,
                free: videos.free,
                description: videos.description
            });
            setUploadVideo({
                ...uploadVideo, categoryId: videos.categoryId, title: videos.title
            })
            const blocks = htmlToDraft(videos.description);
            const {contentBlocks, entityMap} = blocks;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
            let t = []
            for (let i = 0; i < videos.tags.length; i++) {
                t = [...t, videos.tags[i].id]
            }
            setRes({
                data: {
                    id: videos.videoId
                }
            })

            setIds(t);
        }
    }, [editId, videos]);
    return (<Layout>
        <div style={style} className="bg"/>
        <form action="" onSubmit={onSubmit}>

            <div className="wrapperopVideo">
                <div className="wro">
                    <div className="addVideos">Edit Video</div>
                    {/*<div className="icons">*/}
                    {/*    <FiEdit/>*/}
                    {/*</div>*/}

                </div>

                <div className="containerer">
                    <div className="wr">
                        <div className="section">1.Title & Categories</div>
                        <div className="titleImgg">
                            <div className="inputt">
                                <div className="titleOnee">Title</div>
                                <input
                                    placeholder="Enter Your Title"
                                    type="text"
                                    required=""
                                    className="input11"
                                    value={uploadVideo.title}
                                    onChange={(e) => setUploadVideo({
                                        ...uploadVideo, title: e.target.value
                                    })}
                                />
                            </div>
                            <div className="inputti">
                                <div className="titleOnee">Subtitle</div>
                                <input
                                    placeholder="Enter Your Subtitle"
                                    type="text"
                                    required=""
                                    className="input11"
                                    value={videosData.subtitle}
                                    onChange={(e) => setVideosData({
                                        ...videosData, subtitle: e.target.value
                                    })}
                                />
                            </div>
                            <div className="allWr">
                                <div className="category">
                                    <div className="categoryIn">
                                        Category
                                    </div>
                                    <select
                                        value={uploadVideo.categoryId}
                                        onChange={(e) => setUploadVideo({
                                            ...uploadVideo, categoryId: parseInt(e.target.value)
                                        })} name="" id="">
                                        <option value="" disabled={true} selected={true} className="opt">Category
                                        </option>
                                        {categories && categories.map((i, k) => {
                                            return (<option value={i.id} key={k} className="opt">{i.name}</option>)
                                        })}
                                    </select>
                                </div>

                                <div className="category">
                                    <div className="categoryIn">
                                        Age
                                    </div>
                                    <select name="" id=""
                                            value={videosData.childCategoryId}
                                            onChange={(e) => setVideosData({
                                                ...videosData, childCategoryId: parseInt(e.target.value)
                                            })}>
                                        <option value="" disabled={true} selected={true} className="opt">Age
                                        </option>
                                        {age && age.map((i, k) => {
                                            return (<option value={i.id} key={k} className="opt"> {i.name}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="cateAge">
                            <div className="sectionn">2.Video & Image</div>
                            <div className="displayy">
                                <div className="img">
                                    <div className="imgIn">
                                        <div className="titleTwo">Upload Video</div>
                                        <input type="file" accept="video/*" onChange={(e) => handleVideo(e)}/>
                                        <div className="mb30">
                                            <ProgressBar percentage={uploadPercentage} className="progressbar"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="img">
                                    <div className="imgIn">
                                        <div className="titleTwo">Upload Image</div>
                                        <input type="file" accept="image/*" onChange={e => handleImg(e)}/>
                                    </div>
                                    <img className="imgShow" src={image} alt=""/>
                                </div>
                            </div>
                            <div className="desc">
                                <div className="sectionn">
                                    3.Description
                                </div>
                            </div>
                        </div>
                        {/*<div>*/}
                        {/*    Objective*/}
                        {/*    <input type="text" value={videosData.objective} onChange={event => setVideosData({*/}
                        {/*        ...videosData,*/}
                        {/*        objective: event.target.value*/}
                        {/*    })}/>*/}
                        {/*</div>*/}
                        <div className="desc">
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                    options: ["inline", "blockType", // "fontSize",
                                        "fontFamily", "list", "textAlign", "colorPicker", "link", "embedded", "emoji", "image", "remove", "history",],
                                    colorPicker: {
                                        popupClassName: "colorModal",
                                    },
                                    link: {
                                        popupClassName: "colorModal",
                                    },
                                    image: {
                                        popupClassName: "colorModal",
                                    },
                                }}
                            />
                        </div>

                        {/*<div className="tags">*/}
                        {/*    <div className="sectionn">*/}
                        {/*        5.Tags*/}
                        {/*    </div>*/}
                        {/*    <div className="cr">*/}
                        {/*        <div className="tgWr">*/}
                        {/*            {*/}
                        {/*                tagIds && tagIds.map((i, k) => {*/}
                        {/*                    return (*/}
                        {/*                        <div key={k}*/}
                        {/*                             className={`tg ${ids.some(a => a === i.id) ? "yes" : ""}`}*/}
                        {/*                             onClick={() => handLeClick(i.id)}>*/}
                        {/*                            {i.name}*/}
                        {/*                        </div>*/}
                        {/*                    )*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="isFree">
                            <div className="bg">
                                6.Costs & Activity
                            </div>
                            <div className='gh'>
                                <div className='ft'>
                                    <input type="checkbox" checked={videosData.free}
                                           onChange={e => (setVideosData({
                                               ...videosData, free: e.target.checked
                                           }))}/>
                                    Free
                                </div>
                                <div className='ft'>
                                    <input type="checkbox"
                                           checked={videosData.active}
                                           onChange={e => (setVideosData({
                                               ...videosData, active: e.target.checked
                                           }))}/> Active
                                </div>
                                <div className="mb30">
                                    <ProgressBar percentage={uploadPercentage1} className="progressbar"/>
                                </div>
                                <div className="ft">
                                    <input value="Publish" type="submit" className="submit"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </Layout>);
}

const mapStateToProps = (state) => ({
    tags: state.tagsMain.tags,
    ages: state.agesMain.ages,
    editId: state.videosMain.editVideoId,
    videos: state.oneVideoMain,
    pId: state.pagination.video
});

export default connect(mapStateToProps, {
    getArticles, deleteArticles, popUpHandle, getOneArticle, getVideos, popUpBgHandle
})(React.memo(EditVideo));