import axios from "axios";

export const MainApi = "http://134.122.75.184/admin/api";
export const MainWithoutApi = "http://134.122.75.184/admin";
// export const MainApi = "http://192.168.43.82/api/v1";

export const GetToken = () => {
    return localStorage.getItem("token");
    // localStorage.setItem("token", "")
};

const instance = axios.create({
    baseURL: MainApi,
});

instance.interceptors.request.use(
    async (config) => {
        config.headers.Authorization = `Bearer ${GetToken()}`;
        config.headers = {
            ...config.headers,
            "Content-Type": "application/json",
        };
        return config;
    },
    (error) => Promise.reject(error.response)
);

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error.response);
    }
);

export default instance;