import {
    GET_ONE_VIDEO
} from '../../constants/actionTypes';

const initialState = {
    oneVideoMainIn:undefined
};


export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ONE_VIDEO:{
            return state.oneVideoMainIn = action.payload
        }
        default:
            return state;
    }
};