import axios from "./index";

export const getAges = () =>
    axios.get(`child-categories`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}});
export const storeAges = () =>
    axios.post(`child-categories`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}});
export const editAges = () =>
    axios.put(`child-categories`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}});

export const getTags = () =>
    axios.get(`tags`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}});
export const storeTags = () =>
    axios.post(`tags`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}});
export const editTags = () =>
    axios.put(`tags`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}});
export const deleteTags = (id) =>
    axios.delete(`tags/${id}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}});
