import {
    GET_ONE_ARTICLE
} from '../../constants/actionTypes';

const initialState = {
    oneArticle:undefined
};


export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ONE_ARTICLE:{
            return state.oneArticle = action.payload
        }
        default:
            return state;
    }
};