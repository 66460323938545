import {AUTH, LOGOUT, START_AUTH, POP_UP, EDIT, POP_UP_VIDEO, POP_UP_BG} from "../../constants/actionTypes";

const initialState = {
    access: localStorage.getItem("token"),
    loading: false,
    artId: undefined,
    artBool: false,
    editing:false,
    popVideo:false,
    popContent: undefined,
    bgAll:false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case START_AUTH: {
            return {...state, loading: true};
        }
        case AUTH: {
            return {...state, access: true, loading: false};
        }
        case LOGOUT: {
            return {...state, access: false, loading: false};
        }
        case POP_UP: {
            return {
                ...state, artId: action.payload,
                artBool: !state.artBool
            }
        }
        case POP_UP_BG: {
            return {
                ...state,
                bgAll: !state.bgAll,
            }
        }
        case POP_UP_VIDEO: {
            return {
                ...state,
                popContent: action.payload,
                popVideo: !state.popVideo
            }
        }
        case EDIT :{
             return {
                 ...state,
                 editing: !state.editing
             }
        }
        default:
            return state;
    }
};

