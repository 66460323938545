import React from "react";
import {Navigate, useLocation} from "react-router";
import {connect} from "react-redux";
import {getArticles} from "../../actions/articles";

function RequireAuth({access,children}) {
    let location = useLocation();

    if (!access) {
        return <Navigate to="/" state={{ from: location }} />;
    }
    return children;
}

const mapStateToProps = ({auth: {access}}) => ({access});

export default connect(mapStateToProps, {getArticles})(RequireAuth);