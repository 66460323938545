import React, {lazy, Suspense, useEffect} from "react";
import './App.css';
import './assets/app.css'
import './assets/var.scss';
import {Route, Routes} from "react-router-dom"
import Auth from "./pages/auth/auth";
import {connect} from "react-redux";
import {getArticles} from "./actions/articles";
import RequireAuth from "./pages/auth/requireAuth";
import {LoadingOutlined} from '@ant-design/icons';
import {getAgesParams, getTagsParams} from "./actions/params";
import {getVideos} from "./actions/videos";
import {getCategories} from "./actions/categories";
import EditVideo from "./pages/editVideo/editVideo";
import 'antd/dist/antd.css';

const AddArticle = lazy(() => import("./pages/addArticle/addArticle"));
const AddVideo = lazy(() => import("./pages/addVideo/addVideo"));
const Articles = lazy(() => import("./pages/articles/articles"));
const Videos = lazy(() => import("./pages/videos/videos"));
const Params = lazy(() => import("./pages/params/params"));
const EditArticle = lazy(() => import("./pages/editArticle/editArticle"));
const PrivacyPolicyPage = lazy(() => import("./pages/privacy-policy"));
const TermsOfUsePage = lazy(() => import("./pages/terms-of-use"));


function App({access, getArticles, getAgesParams, getTagsParams, getVideos, getCategories}) {
    useEffect(() => {
        getArticles(10, 0);
        getVideos(10, 0);
        getAgesParams();
        getTagsParams();
        getCategories();
    }, [])
    return (
        <div className="App">
            <Suspense fallback={<LoadingOutlined style={{fontSize: 24}} spin/>}>
                <Routes>
                    <Route path={'/'} element={<Auth/>} exact={true}/>
                    <Route
                        path="/addarticle"
                        element={
                            <RequireAuth>
                                <AddArticle/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/editarticle"
                        element={
                            <RequireAuth>
                                <EditArticle/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'/addvideo'}
                        element={
                            <RequireAuth>
                                <AddVideo/>
                            </RequireAuth>
                        }
                    />

                    <Route
                        path={'/articles'}
                        element={
                            <RequireAuth>
                                <Articles/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'/videos'}
                        element={
                            <RequireAuth>
                                <Videos/>
                            </RequireAuth>
                        }
                    />

                    <Route
                        path={'/params'}
                        element={
                            <RequireAuth>
                                <Params/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'/editarticle'}
                        element={
                            <RequireAuth>
                                <EditArticle/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'/editvideo'}
                        element={
                            <RequireAuth>
                                <EditVideo/>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path={'/privacy-policy'}
                        element={<PrivacyPolicyPage/>}
                    />
                    <Route
                        path={'/terms-of-use'}
                        element={<TermsOfUsePage/>}
                    />
                </Routes>
            </Suspense>
        </div>
    );
}

const mapStateToProps = ({auth: {access}}) => ({access});

export default connect(mapStateToProps, {getArticles, getTagsParams, getAgesParams, getVideos, getCategories})(App);
