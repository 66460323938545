import React from 'react';
import './sidebar.scss'
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";


function Sidebar({bool, editing}) {
    return (
        <div className={`sidebar ${!bool ? "close" : ""}`}>
            <div className="baby">
                Farzandim
            </div>
            <NavLink to={'/addarticle'} activeclassname="active" className="inactive">Maqola Qo'shish</NavLink>
            <NavLink to={'/addvideo'} activeclassname="active" className="inactive">Video Maqola Qo'shish</NavLink>
            <NavLink to={'/articles'} activeclassname="active" className="inactive">Maqolalar</NavLink>
            <NavLink to={'/videos'} activeclassname="active" className="inactive">Video Maqolalar</NavLink>
            <NavLink to={'/params'} activeclassname="active" className="inactive">Tahrirlash</NavLink>

        </div>
    );
}

const mapStateToProps = (state) => ({
    categories: state.categories.categories,
    articles: state.oneArt,
    editing:state.auth.editing

});

export default connect(mapStateToProps, {})(Sidebar);